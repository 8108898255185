import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import "./topo.sass";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { QrCodeAppContext } from "../qrcodeappContext";

export function Topo({ escuro }) {
  const { isOpen, toggle } = useContext(QrCodeAppContext)

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [window.location]);
  return (
    <>
      <Navbar bg={escuro ? 'dark' : 'light'} variant={escuro ? 'dark' : 'light'} t expand="lg" className="topo" sticky="top">
        <Container>
          <Link className="nav-brand" style={{ width: '180px' }} to="/" preventScrollReset={true} >
            {escuro ? <img src="../logo_dark.png" className="logo" alt="logo uxbank" /> : <img src="../logo.png" alt="logo uxbank" className="logo" />}
          </Link>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"lg"}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${"lg"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"lg"}`}>
                <Link className="nav-brand" to="/" preventScrollReset={true} >
                  <img src="../logo.png" style={{ width: '180px' }} className="logo" alt="logo uxbank" />
                </Link>

              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">

                <Nav.Item>
                  <Link className="nav-link" to="/sobre" preventScrollReset={true} >
                    Sobre ux bank
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link className="nav-link" to="/conta-digital" preventScrollReset={true} >
                    Conta digital
                  </Link>
                </Nav.Item>

                <Nav.Item>
                  <Link className="nav-link" to="/fale-conosco" preventScrollReset={true} >
                    Fale conosco
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  {/* <Link className="nav-link" to="/tire-suas-duvidas" preventScrollReset={true} >
               Tire suas dúvidas
               </Link> */}
                </Nav.Item>
                <Nav.Item >
                  <Link className="nav-link" to="/politica-de-privacidade" preventScrollReset={true} >Política de Privacidade</Link>
                </Nav.Item>

              </Nav>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Item className="destaque"  >
                  <a className="nav-link" href="https://webbanking.uxbank.secure.srv.br/" target={"_blank"} preventScrollReset={true} >Abra a sua conta</a>
                </Nav.Item>
              </Nav>
               <Nav>
               <Nav.Item className="portal" >
                  <a className="nav-link" preventScrollReset={true} >Portal do Investidor</a>
                </Nav.Item>

               </Nav>

            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

    </>
  );
}
