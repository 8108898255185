import { useContext } from "react";
import { QrCodeAppContext } from "../../../template/qrcodeappContext";
import "./abrasuaconta.sass";
export function AbraSuaConta() {
  const {isOpen,toggle} = useContext(QrCodeAppContext)

  return (
    <>
      <div className="abrasuaconta">
        <div className="efeito">
            <img src="efeito.svg"/>
        </div>
  
        <div className="container">

          <div className="row">
          <div className="col-sm-4">
          <div className="img ms-auto flex ">
            <img src="celularmao.png"  alt="Mulher segurando um celular com a logo da ux bank "  style={{zIndex: '999'}}  className="m-auto" />
          </div>
          </div>
          <div className="col-sm-8">

          <div className="flex">
            <div className="m-auto texto">
            <h3>
            Baixe nosso aplicativo
e abra sua conta <br></br>em poucos minutos.
            </h3>
          
            <div>
              <a
                className="ui btn btn-primary"
                href="https://webbanking.uxbank.secure.srv.br/" target={"_blank"} 

              >
       Abra sua conta digital
              </a>

              
            </div>
            <div className="flex gap-2">
            <img src="./icones/google-badge.png" alt="imagem download google play"/>
            <img src="./icones/apple-badge.png" alt="imagem download App Store"/>
            </div>
            </div>
       
         
          </div>
          </div>
          </div>
    
    
         
        </div>
      </div>
    </>
  );
}
