import React, { memo } from 'react';
import { Pagina } from '../../template/pagina/pagina';
import { AbraSuaConta } from './abrasuaconta';
import {BemVindo} from './bemvindo'
import {Servicos} from './servicos'
import { Sobre } from './sobre';
import { VenhaParaIbipay } from './venha';
export default memo(function PaginaPrincipal() {
    return <>
<Pagina titulo="teste">
<>
<BemVindo/>
<AbraSuaConta/>
<Servicos/>
<Sobre/>
<VenhaParaIbipay/>
</>
</Pagina>
    </>;
  });
  