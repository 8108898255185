import * as React from 'react';
import './rodape.sass'
import { Link } from 'react-router-dom';
export function Rodape() {
   
    return (<> 
<footer>
  <div className='container'>
  <div className="row">
  <div className="col-sm-5">
     <>
     <img className='logo' src='../logo.png'  alt="logo ibipay rodape" height={50} style={{margin: '2rem 0'}}></img>
     <p style={{fontSize: '1.2rem'}}>
     Sobre o
ux bank
     </p>
     <p style={{fontSize: '.9rem'}}>
     Muito prazer, somos a ux bank, um novo banco digital<br></br> que com finalidade de aumentar a experiencia de nossos clientes <br></br>ao mercado financeiro. 

</p>
<p style={{fontSize: '.9rem'}}>

<strong>Dimension Office & Park*</strong> <br></br>
Av. Emb. Abelardoo Bueno nº 1, Bl. Ayrton Senna 2, Sl 221. <br></br>
Barra da Tijuca - Rio de Janeiro /RJ. <br></br>
CEP: 22.775-040.
</p>
<p style={{fontSize: '.9rem'}}>
{/* Av. Trez  de Julho, 50 - Jardim Aclimação, São Paulo - SP.<br></br>
00000-000 */}
</p>
     </>
    </div>
    <div className="col-sm">
    <h4>Produtos e Serviços</h4>
    <ul class="menu-rodape">
    <li class="list-group-item"><Link to={'/conta-digital'}>Cartões</Link></li>
    <li class="list-group-item"><Link to={'/conta-digital'}>Pix</Link></li>
    <li class="list-group-item"><Link to={'/conta-digital'}>Investimentos</Link></li>
    <li class="list-group-item"><Link to={'/conta-digital'}>Credito</Link></li>
    <li class="list-group-item"><Link to={'/conta-digital'}>Portabilidade de credito</Link></li>
    <li class="list-group-item"><Link to={'/fale-conosco'}>Fale conosco</Link></li>

</ul>
<h4>Empresa</h4>
<ul class="menu-rodape">
    <li class="list-group-item"><Link to={'/sobre'}>Sobre nós</Link></li>
    <li class="list-group-item"><Link to={'/conta-digital'}>Clientes</Link></li>
    <li class="list-group-item"><Link to={'/fale-conosco'}>Fale conosco</Link></li>

</ul>
    </div>
    <div className="col-sm">
     
<p className='info'>Central de Relacionamento de Segunda a sexta, das 10h às 18h.</p>
<p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  Capitais e regiões metropolitanas<br></br>
	+55 (11) 91608-0863
  </span>
</p>

<p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  Outras localidades<br></br>
	+55 (11) 91608-0863
  </span>
</p>

<p className='icon'>
<i class="fa-brands fa-whatsapp"></i>
  <span>
  Deficientes auditivos ou de fala
WhatsApp<br></br>
+55 (11) 91608-0863

  </span>
</p>

<p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  SAC (Reclamações, dúvidas e sugestões)<br></br>
atendimento 24h<br></br>
(11) 91608-0863
  </span>
</p>

<p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  Ouvidoria<br></br>
segunda a sexta, das 10h às 16h<br></br>
(exceto feriados)<br></br>
(11) 91608-0863
  </span>
</p>



    </div>
  </div>
  </div>
  <div className='bar container'>
  <div className="row">
    <div className='col-sm d-flex flex-row py-3 links'>
    <Link className='nav-link' to="/politica-de-privacidade" preventScrollReset={true}>Política de Privacidade</Link>
    <Link className='nav-link' to="/politica-corporativa-de-seguranca-da-informacao-e-cyber-security" preventScrollReset={true}>Política corporativa de segurança da informação e cyber security (global) </Link>
    </div>
    <div className='col-sm d-flex redes py-3'>
    {/* <a>
    <i class="fa-brands fa-twitter"></i>
        </a> */}
        <a href='https://www.facebook.com/profile.php?id=61556930606393' target="_blank">
        <i class="fa-brands fa-facebook-f"></i>
        </a>
        <a href='https://www.linkedin.com/company/ux-bank' target="_blank">
        <i class="fa-brands fa-linkedin-in"></i>
        </a>
        <a href='https://www.instagram.com/uxbankbr' target="_blank">
        <i class="fa-brands fa-instagram"></i>
        </a>
    

    </div>
  </div>
  </div>
  <div className='copyright'>
    <div className='container'>
    UX BANK  © 2024 - Todos os direitos reservados CNPJ: 37.913.554/0001-90
    </div>
</div>
</footer>
<div className='whatsapp'>
<a href='https://wa.me/5511916080863' target='_blank'>
<i class="fa-brands fa-whatsapp"></i>
</a>
</div>
    </>)
}