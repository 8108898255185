import React,{ memo, useContext } from "react";
import { Card, CardGroup, Col, Row } from "react-bootstrap";
import { Pagina } from '../../template/pagina/pagina';
import { PaginaEscura } from "../../template/pagina/paginaescura";
import { QrCodeAppContext } from "../../template/qrcodeappContext";
import './sobre.sass'
export default memo(function SobrePagina({}) {
  const {isOpen,toggle} = useContext(QrCodeAppContext)

return(<>
<Pagina escuro={true}>
<>

<div className="sobre-bemvindo" style={{background: `url("./quemsomos.jpg")`}}>
<div className="container" >
<div className="row py-4">
<div className="col-sm-8">

<div className="card bg-dark text-light">
        <div className="card-body">
        <h2>Olá!
Bem-vindo ao  <br></br>ux bank</h2>

<p>
É um prazer poder apresentar a UX Bank, somos uma instituição financeira digital inovadora e comprometida em aprimorar a experiência dos nossos clientes no mercado financeiro.<br></br> 
Com esse propósito em mente, desenvolvemos o conceito "UX" (User eXperience).<br></br> 
Nossa missão é democratizar, simplificar e transformar a experiência dos nossos clientes com relacinamento e atendimento personalizado.
</p>

<a className="btn btn-primary" href="https://webbanking.uxbank.secure.srv.br/" target={"_blank"} >
Abra sua conta digital
</a>
        </div>
</div>

        </div>
        <div className="col-sm">
        </div>
</div>

</div>
</div>
<div className="vantagens">
<div className="container">
<h2 className="title">
Conheça os <strong>benefícios</strong> <br></br> ao abrir sua conta ux bank
</h2>
<Row sm="3" className="g-4 beneficios">
  
        <Col sm>
        <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title> <i class="fa-solid fa-hand-holding-dollar"></i> Serviços on-line</Card.Title>
          <Card.Text>
            This is a wider card with supporting text below as a natural lead-in
            to additional content. This card has even longer content than the
            first to show that equal height action.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>
      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title> <i class="fa-solid fa-star"></i> Aplicativo com acesso fácil</Card.Title>
          <Card.Text>
          Nosso aplicativo possui acesso fácil para trazer praticidade, agilidade e eficiência para suas atividades bancárias.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>
      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title> <i class="fa-solid fa-mobile-screen-button"></i> Recarga de Celular</Card.Title>
          <Card.Text>
          Recarregue seu celular diretamente da plataforma digital por débito em conta.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title><i class="fa-solid fa-receipt"></i> Pagamentos de contas e boletos</Card.Title>
          <Card.Text>
          Realize pagamentos de
contas direto pelo aplicativo.
Sem filas e sem atrasos.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title> <i class="fa-brands fa-pix"></i>Pix</Card.Title>
          <Card.Text>
          Faça transferências via Pix
em poucos minutos.
O dinheiro cai na sua conta
na hora e com toda segurança.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title><i class="fa-solid fa-money-bill-transfer"></i>Transferências</Card.Title>
          <Card.Text>
          Envie e receba seu dinheiro de
forma rápida e sem burocracias.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

  

    </Row>
<div className="d-flex p-4">
<a className="btn btn-primary mx-auto" href="https://webbanking.uxbank.secure.srv.br/" target={"_blank"} >
Baixe o aplicativo
</a>
</div>
</div>
</div>
<div className="conheca">
<div className="container">
<div className="row py-4">
<div className="col-sm flex">
        <div className="m-auto">
        <h2>
Conheça o ux bank<br></br>
Conheça a liberdade!<br></br>
</h2>
<p>
Baixe nosso aplicativo e abra sua conta
em poucos minutos.</p>
<a className="btn btn-primary mx-auto" href="https://webbanking.uxbank.secure.srv.br/" target={"_blank"} >
Baixe o aplicativo
</a>
        </div>

</div>
<div className="col-sm flex">
<img src="celularmao.png"  style={{zIndex: '999'}} className="m-auto" />
</div>
</div>
</div>
</div>
</>
</Pagina>
</>);

});