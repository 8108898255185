import { Link, Outlet } from 'react-router-dom';
import { Rodape } from '../rodape/rodape';
import { Topo } from '../topo/topo';
import CookieConsent from 'react-cookie-consent'
import { QrCodeapp } from '../qrcodeapp';
export function Pagina({titulo, children,escuro}) {
    return (<>
    <QrCodeapp/>
    <Topo escuro={escuro}/>
        <div>
            {/* <div className="bar">
                <div className="ui container" >
                    <h4 className="ui header">
                        <div className="content">{titulo}</div>
                    </h4>
                </div>
            </div> */}
            {/* <div className="ui conteudo pagina container"> */}
                {children}
            {/* </div> */}
        </div>



        <Rodape/>
<CookieConsent
        declineButtonText="Rejeitar"
        location="bottom"
        buttonText="Aceitar Todos os Cookies"
        cookieName="uxbankCookies"
        enableDeclineButton
        style={{ background: "#fff" }}
        buttonStyle={{
          color: "#000", margin: '.5rem 4px', background: '#065ED1',
          fontSize: ".8rem", padding: ".5rem", borderRadius: '.3rem'
        }}
        declineButtonStyle={{ color: "#000", margin: '.5rem 4px', background: 'rgba(0,0,0,0.2)',
        fontSize: ".8rem", padding: ".5rem", borderRadius: '.3rem' }}
        expires={150}
      >
        
 
 
        <i class="fa-solid fa-cookie-bite"></i>
       Este site usa cookies e outras tecnologias para coletar dados. Ao aceitar todos os cookies, você concorda com a coleta dos cookies essenciais, de experiência, funcionais e de publicidade. Você também pode personalizar suas preferências ao configurar os cookies. Entenda mais sobre isso em noss <Link to="/politica-de-privacidade" >Política de Privacidade e cookies</Link>
      </CookieConsent>
    </>)
}