import React, { Children } from 'react'
import { Offcanvas } from 'react-bootstrap';
import { QrCodeAppContext } from './qrcodeappContext'

export function QrCodeapp({ children }) {
    const { isOpen, toggle } = React.useContext(QrCodeAppContext)
    function handleClose() {
        toggle();
      }
    return (


<>
        <Offcanvas show={isOpen} placement={"end"} onHide={handleClose} backdrop="true" scroll="true" >
        <Offcanvas.Header closeButton >
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='text-center p-5'>
          <h2>Abra sua conta digital</h2>
        <p>Ligue a câmera do seu celular e aponte para o QR Code abaixo.</p>
        <p>Você será redirecionado para a loja de aplicativos.</p>
        <p>Aí é só baixar o app do ux bank e abrir a sua conta.</p>
        <div className='d-flex'>
        <img src="../../qrcode.svg" className='m-auto' width={"80%"}/>
        </div>
        </Offcanvas.Body>
      </Offcanvas>
</>
    )
}

