import React, { memo } from "react";
import { Card, Container, Table } from "react-bootstrap";
import { Header } from "../../template/header/header";
import { Pagina } from '../../template/pagina/pagina';
import './politicas.sass'
export default memo(function PoliciadePrivacidadePagina() {

        return (<>
                <Pagina>
                        <>
                                <Header titulo={"Política de privacidade de dados"} subtitulo={"POLÍTICA DE PRIVACIDADE - Setembro/2023 "} icon={"fa-solid fa-shield"} img={'./../politica-de-privacidade.jpg'}></Header>


                                <Container className="politicas">
                                        <br></br>
                                        Nós, da <strong>UX BANK</strong>, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 37.913.554/0001-90, levamos a sua privacidade e a proteção dos seus dados a sério.

                                        <br></br>

                                        Por entendermos que a privacidade de suas informações é importante e estarmos comprometidos em cumprir as diretrizes da Lei Geral de Proteção de Dados (LGPD)
                                        quanto ao uso dos dados pessoais, desenvolvemos o presente <strong>Política de Privacidade</strong>, a qual tem como propósito informar a vocês, usuários e parceiros da <strong>UX BANK</strong>,
                                        como tratamos seus dados pessoais, quais são os seus direitos e como você pode exercê-los.
                                        <p>

                                                Esperamos que as informações auxiliem na compreensão do tratamento de seus dados e que demonstrem o compromisso da <strong>UX BANK</strong> com a privacidade, proteção de dados e segurança das informações.
                                        </p>

                                        <br></br>


                                        <h4>
                                                1. Conceitos Básicos: O que você precisa saber para compreender esta Política?
                                        </h4>
                                        <p>
                                                A fim de descomplicar a sua leitura deste documento, apresentamos algumas definições úteis para sua interpretação, conforme a disposição da Lei Geral de Proteção de Dados (Lei nº 13.709/18).
                                        </p>


                                        <Table striped bordered >
                                                <thead>
                                                        <tr><th><strong>TERMO</strong></th><th><strong>CONCEITO</strong></th></tr>
                                                </thead>
                                                <tbody>
                                                        <tr>
                                                                <td><strong>Dado Pessoal
                                                                </strong></td> <td>É uma informação relacionada a uma pessoa física e que seja capaz de identificar a pessoa ou tornar possível a sua identificação. São exemplos de dados pessoais que podem permitir a sua identificação: Nome, CPF, telefone, e-mail, placa do seu veículo etc.  </td>
                                                        </tr>
                                                        <tr>
                                                                <td><strong>Tratamento</strong></td> <td>É toda forma de uso que podemos fazer dos seus Dados Pessoais, incluindo, mas não se limitando às seguintes atividades: coleta, armazenamento, consulta, uso, compartilhamento, transmissão, classificação, reprodução, exclusão e avaliação.  </td>
                                                        </tr>
                                                        <tr>
                                                                <td><strong>Backup</strong></td> <td>É toda e qualquer forma de cópia de segurança de dados computacionais, que pode ser consultada e/ou utilizada quando em momento posterior à sua restauração, em situações de indisponibilidade, alteração ou perda dos dados originais.  </td>
                                                        </tr>
                                                        <tr>
                                                                <td><strong>Cookies</strong></td> <td>São pequenos arquivos de textos utilizados para reconhecer seu navegador ou dispositivo, saber mais sobre seus interesses e oferecer a você recursos e serviços essenciais, além de finalidade adicionais, incluindo: Armazenar Preferências e Configuração; Segurança; Redes 
Sociais; Publicidade com Base em Interesses; Desempenho.  </td>
                                                        </tr>
                                                        <tr>
                                                                <td><strong>Titular</strong></td> <td>É você, a pessoa física a quem os Dados Pessoais se referem.  </td>
                                                        </tr>
                                                        <tr>
                                                                <td><strong>Empresa</strong></td> <td>
                                                        
                                                                Somos nós, a <strong>UX BANK.</strong>  </td>
                                                        </tr>




                                                </tbody>
                                        </Table>
                                        <h4>2. Como Coletamos os Dados Pessoais?
                                        </h4>
                                        <p>Os seus dados pessoais poderão ser coletados através das seguintes ações, a saber:  </p>
                                        <ol type="a">
                                                <li>Cadastro de conta junto à <strong>UX BANK.</strong></li>
                                                <li>Contratação dos serviços da <strong>UX BANK.</strong> </li>
                                                <li>Utilização do suporte ao usuário, canais de atendimento, pesquisa e treinamentos;</li>
                                                <li>Utilização das plataformas digitais da <strong>UX BANK.</strong></li>
                                                <li>Divulgação de conteúdo fornecido pelo usuário à  <strong>UX BANK;</strong> </li>
                                                <li>Realização de transação envolvendo o usuário; </li>
                                                <li>Informações sobre produtos e serviços; </li>
                                                <li>Participação do usuário em campanha de marketing da <strong>UX BANK.</strong>  </li>

                                        </ol>
                                        <p>Informamos, ainda, que a coleta de dados poderá ser realizada via terceiros quando permitido pela legislação.
                                        </p>

                                        <h4>3. Que Tipo de Dados Pessoais a<strong> UX BANK  </strong>Trata e Por Quê? </h4>

                                        <p>A  <strong>UX BANK</strong> coleta e utiliza dados pessoais, de forma a viabilizar a prestação de serviços e aprimorar a experiência de uso, estes dados farão parte do ecossistema de comunicação, seja para informar novos produtos, comunicados específicos regulatórios, ofertas e promoções de soluções, bem como pesquisas de satisfação. 

                                        </p>

                                        <p>
                                             A  <strong>UX BANK</strong> poderá coletar as informações inseridas ativamente pelo Usuário no momento do cadastro e, ainda informações coletadas automaticamente quando da utilização das páginas e da rede. Listamos abaixo algumas situações em que nós podemos tratar seus dados pessoais no contexto da sua relação com a gente: 

                                        </p>


                                        <Table striped bordered >

                                                <tbody>
                                                        <tr>
                                                                <td><strong>TIPOS DE DADOS PESSOAIS FORNECIDOS PELOS
                                                                        USUÁRIOS</strong></td>
                                                                <td><strong>FINALIDADES DE TRATAMENTO </strong></td>
                                                        </tr>
                                                        <tr>
                                                                <td><ul>
                                                                        <li>Nome Completo</li>
                                                                        <li>Endereço de E-mail </li>
                                                                        <li>Número de Telefone</li>
                                                                        <li>Empresa</li>
                                                                </ul></td>
                                                                <td>Dados essenciais para promover a divulgação de
                                                                        material publicitário, promoções de produtos e ações
                                                                        de marketing.</td>
                                                        </tr>

                                                        <tr>
                                                                <td><strong>TIPOS DE DADOS PESSOAIS COLETADOS
                                                                        AUTOMATICAMENTE</strong></td>
                                                                <td><strong>FINALIDADES DE TRATAMENTO</strong></td>
                                                        </tr>
                                                        <tr>
                                                                <td><ul>
                                                                        <li>Endereço de IP</li>
                                                                        <li>Geolocalização </li>
                                                                        <li>Registro de Data e Hora de Acesso</li>
                                                                        <li>Relação das Telas Acessadas na
                                                                                Plataforma</li>
                                                                </ul></td>
                                                                <td>Rastrear os acessos com a finalidade de prevenir fraudes e demais riscos que possam comprometer a
                                                                        segurança da informação.<br></br><br></br>
                                                                        Identificação da persona, dos interesses do usuário
                                                                        dentro da plataforma, que correspondem às
                                                                        informações sobre os hábitos de navegação. Ainda,
                                                                        para verificação dos melhores horários para contato. </td>
                                                        </tr>
                                                </tbody>
                                        </Table>





                                        <h4>4. Com Quem a<strong> UX BANK  </strong>Compartilha os Seus Dados Pessoais? </h4>
                                        <p>Nós operamos em parceria com uma série de outras organizações para viabilizar as nossas atividades. Descrevemos, a seguir, algumas situações em que podemos compartilhas os dados pessoais coletados:  </p>

                                        <ol type="a">
                                                <li>Prestadores de serviços que atuem em favor da UX BANK; 
</li>
                                                <li>Parceiros de negócios; </li>
                                                <li>Campanhas de publicidade;</li>
                                                <li>Empresa responsável pelo tratamento de dados (operador), a exemplo do armazenamento de dados em
                                                        servidores de terceiros;</li>
                                                <li>Terceiros que estejam fornecendo em conjunto iniciativas de vendas, eventos ou promoções, mediante
                                                        consentimento;</li>
                                                <li>Autoridades em geral, para cumprimento de determinação legal ou regulatória; </li>
                                                <li>Terceiros necessários para operação dos nossos serviços; </li>
                                                <li>
                                                        Terceiros, para fins de controle e prevenção de fraude.
                                                </li>
                                        </ol>

                                        <h4>5. Armazenamento de Dados </h4>

                                        <p>
                                        A<strong> UX BANK  </strong>possui uma política de retenção de dados pessoais alinhada com a lei aplicável. Os seus dados serão armazenados somente pelo período necessário para o atendimento da finalidade para a qual foram coletados, principalmente para a execução do contrato, salvo se houver qualquer outra razão para sua manutenção, como, por exemplo, cumprimento de obrigações legais, regulatórias, contratuais e exercício regular de direito. 

                                        </p>
                                        <p>
                                        Sempre realizamos uma análise técnica para determinar o período de retenção adequado para cada tipo de dado pessoal coletado, considerando sua natureza, necessidade de coleta e finalidade para qual o dado será tratado, bem como eventuais necessidades de retenção para o cumprimento de obrigações ou o resguardo de direitos. 

                                        </p>

                                        <p>Nos demais casos, iremos proceder à exclusão de nossas bases ou realizar a anonimização, ocasião em que
                                                você não poderá mais ser identificado. </p>

                                        <h4>6. Como Protegemos os Seus Dados?  </h4>

                                        <p>Nossa responsabilidade é cuidar dos seus dados pessoais e utilizá-los somente para as finalidades descritas nesta Política. Para garantir a sua privacidade e a proteção dos seus dados pessoais, a <strong>UX BANK</strong> adota todas as medidas técnicas e organizacionais para a proteção de seus dados. Para tanto, advertimos que não solicitaremos a sua senha, exceto quando você fizer login nos canais oficiais da <strong>UX BANK</strong>, e que você é responsável por manter em sigilo a senha de acesso na plataforma.  </p>


                                        <h4>7. Direitos do Titular   </h4>

                                        <p>Os dados pessoais são seus e a lei brasileira, através da LGPD e demais legislações, garantem uma série de direitos relacionados a eles. Nós estamos comprometidos com o cumprimento desses direitos e vamos, na sequência, explicar quais são esses direitos e como você pode exercê-los:  </p>

                                        <Table striped bordered >
                                                <tbody>
                                                        <tr>
                                                                <td><strong>Confirmação e Acesso
                                                                </strong></td>
                                                                <td>Você pode solicitar a confirmação sobre a existência de tratamento dos seus Dados Pessoais para que, em caso positivo, você possa solicitar cópias dos registros que temos sobre você. </td>
                                                        </tr>

                                                        <tr>
                                                                <td><strong>Correção
                                                                </strong></td>
                                                                <td>Você pode solicitar a correção dos seus Dados Pessoais caso estes estejam incompletos, inexatos ou desatualizados. </td>
                                                        </tr>

                                                        <tr>
                                                                <td><strong>Anonimização, Bloqueio ou
                                                                        Eliminação
                                                                </strong></td>
                                                                <td>Você pode solicitar (a) a anonimização dos seus Dados Pessoais, de forma que eles não possam mais ser relacionados a você e, portanto, deixem de ser Dados Pessoais; (b) o bloqueio dos seus Dados Pessoais, suspendendo temporariamente a sua possibilidade de os tratarmos para certas finalidades; e (c) a eliminação dos seus Dados Pessoais, caso em que deveremos apagar todos os seus Dados Pessoais sem possibilidade de reversão. </td>
                                                        </tr>

                                                        <tr>
                                                                <td><strong>Portabilidade</strong></td>
                                                                <td>Você pode solicitar o fornecimento de seus Dados Pessoais em formato estruturado e interoperável visando à sua transferência para um terceiro, desde que essa transferência não viole a propriedade intelectual ou segredo de negócios da UX BANK. </td>
                                                        </tr>

                                                        <tr>
                                                                <td><strong>Informações a Respeito do
                                                                        Compartilhamento </strong></td>
                                                                <td>Você tem o direito de saber quais são as entidades públicas e privadas com as quais a<strong> UX BANK  </strong>realiza uso compartilhado dos seus Dados Pessoais. Manteremos, no item 3 dessa Política, uma indicação das nossas relações com terceiros que podem envolver o compartilhamento de Dados Pessoais. Em todo caso, se você tiver dúvidas ou quiser mais detalhes, você tem o direito de nos solicitar essas informações. A depender do caso, podemos limitar as informações fornecidas a você caso a sua divulgação possa violar a propriedade intelectual ou segredo de negócios da UX BANK.  </td>
                                                        </tr>

                                                        <tr>
                                                                <td><strong>Informação sobre a Possibilidade
                                                                        de Não Consentir </strong></td>
                                                                <td> Você tem o direito de receber informações claras e completas sobre a possibilidade e as consequências de não fornecer consentimento, quando ele for solicitado pela UX BANK. O seu consentimento, quando necessário, deve ser livre e informado. Portanto, sempre que pedirmos seu consentimento, você será livre para negá-lo, nesses casos, é possível que alguns serviços não possam ser prestados. </td>
                                                        </tr>

                                                        <tr>
                                                                <td><strong>Revogação do Consentimento
                                                                </strong></td>
                                                                <td> Caso você tenha consentido com alguma finalidade de tratamento dos seus Dados Pessoais, você pode sempre optar por retirar o seu consentimento. No entanto, isso não afetará a legalidade de qualquer Tratamento realizado anteriormente à revogação. Se você retirar o seu consentimento, é possível que fiquemos impossibilitados de lhe prestar certos serviços, mas iremos avisá-lo quando isso ocorrer.  </td>
                                                        </tr>

                                                        <tr>
                                                                <td><strong>Oposição</strong></td>
                                                                <td>A lei autoriza o tratamento de Dados Pessoais mesmo sem o seu consentimento ou um contrato conosco. Nessas situações, somente trataremos seus Dados Pessoais se tivermos motivos legítimos para tanto como, por exemplo, prevenir fraudes. Caso você não concorde com alguma finalidade de tratamento dos seus Dados Pessoais, você poderá apresentar oposição, solicitando a sua interrupção.  </td>
                                                        </tr>
                                                </tbody>
                                        </Table>
                                        <Card className="text-center " bg="info">
                                                <Card.Header>
                                                        <strong>Avisos Importantes </strong>
                                                </Card.Header>
                                                <Card.Body>
                                                Para sua segurança, sempre que você apresentar uma requisição para exercer seus direitos, a<strong> UX BANK  </strong>poderá solicitar algumas informações e/ou documentos complementares para que possamos comprovar a sua identidade, buscando impedir fraudes. Fazemos isso para garantir a segurança e a privacidade de todos. 
 
 Em alguns casos, a<strong> UX BANK  </strong>pode ter motivos legítimos para deixar de atender a uma solicitação de exercício de direitos. Essas situações incluem, por exemplo, casos em que uma revelação de informações específicas poderia violar direitos de propriedade intelectual ou segredos de negócio da<strong> UX BANK  </strong>ou de terceiros, bem como casos em que pedidos de exclusão de dados não possam ser atendidos em razão da existência de obrigação da<strong> UX BANK  </strong>de reter dados, seja para cumprir obrigações legais, regulatórias ou para possibilitar a defesa da<strong> UX BANK  </strong>ou de terceiros em disputas de qualquer natureza. 
  
 Ainda, algumas solicitações podem não ser respondidas de forma imediata, mas a<strong> UX BANK  </strong>se compromete a responder todas as requisições em um prazo razoável e sempre em conformidade com a legislação aplicável. 

                                                </Card.Body>

                                        </Card>
                                        <p>
                                        Caso você tenha alguma dúvida sobre essas questões ou sobre como você pode exercer seus direitos, fique à vontade para entrar em contato conosco por meio dos canais informados ao final desta Política. 

                                        </p>
                                        <h4>
                                                8. Transferência Internacional de Dados
                                        </h4>
                                        <p>
                                        Alguns dados poderão ser armazenados em outros países, já que<strong> UX BANK  </strong>utiliza ou utilizará o sistema baseado em Cloud. Em sendo o caso, serão atendidas todas as normas referentes à proteção de dados. 

                                        </p>
                                        <h4>
                                                9. Coleta de Uso de Informações Pessoais de Crianças e Adolescentes

                                        </h4>

                                        <p>
                                        A plataforma da<strong> Como estamos sempre buscando melhorar nossos serviços e a forma como operamos, a presente Política de Privacidade poderá ser atualizada a qualquer tempo pela <strong> UX BANK  </strong> para refletir as melhorias realizadas. Desta forma, recomendamos a visita periódica desta página para que você tenha conhecimento sobre as modificações efetivadas.  </strong>não se destina à criança e ao adolescente. Nesse sentido, fica vedado o uso da plataforma por usuário com idade inferior a 18 (dezoito) anos. 

                                        </p>

                                        <h4>
                                                10. Canal de Comunicação
                                        </h4>
                                        <p>
                                        Se você acredita que seus dados pessoais foram tratados de maneira incompatível com esta Política ou com as suas escolhas quanto Titular dos seus dados pessoais, ou, ainda, caso tenha dúvidas, comentários ou sugestões relacionadas a esta Política de Privacidade, solicitamos que seja realizado contato diretamente com nossa Encarregada (DPO), através do endereço de e-mail “dpo@SimplixBank.com.br” ou pelo telefone nº (21) 9.7686-9442. 

                                        </p>
                                        <h4>11. Atualização da Política de Privacidade
                                        </h4>
                                        <p>Como estamos sempre buscando melhorar nossos serviços e a forma como operamos, a presente Política de Privacidade poderá ser atualizada a qualquer tempo pela<strong> UX BANK  </strong>para refletir as melhorias realizadas. Desta forma, recomendamos a visita periódica desta página para que você tenha conhecimento sobre as modificações efetivadas. </p>
                                </Container>

                        </>
                </Pagina>
        </>);

});