import React,{ memo, useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Pagina } from '../../template/pagina/pagina';
import "./contadigital.sass"
import "./contapjdigital.sass"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { QrCodeAppContext } from "../../template/qrcodeappContext";
export default memo(function ContaDigitalPagina() {
  const {isOpen,toggle} = useContext(QrCodeAppContext)

return(<>
<Pagina>
<div className="contadigital ">
<div className="degrade ">
<Container>
<Row>
    <Col sm className="flex">
    <div className="m-auto p-4">
    <h1>
Abra sua conta
digital gratuita
</h1>
<p>
Mais que uma conta digital completa: um super app
com vários serviços para simplificar seu dia a dia. 
</p>
<a className="btn btn-primary" href="https://webbanking.uxbank.secure.srv.br/" target={"_blank"} >Abra sua conta</a>

    </div>

    </Col>
    <Col sm className="order-mobile">
        <img src="./mulheruxbank.png" />
    </Col>
</Row>
</Container>
</div>






<div className="container servicos-conta">

<Row  className="g-4 ">
<Col sm className="d-flex">
<div className="m-auto">
<h2 className="title">
Todos os serviços que você precisa, online e sem burocracia!
</h2>
<a className="btn btn-primary mx-auto" onClick={() => toggle(true)}>
Baixe o aplicativo
</a>
</div>

    </Col>
    <Col sm={8}>
  
        

                <Carousel
  swipeable={true}
  draggable={true}
  showDots={true}
  responsive={ {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
      paritialVisibilityGutter: 20

    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2 ,
      paritialVisibilityGutter: 20

    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2 ,
      paritialVisibilityGutter: 40

    }
  }}
  ssr={true} // means to render carousel on server-side.
  customTransition="all .5"
  partialVisbile
  transitionDuration={500}
  arrows={false}
  containerClass="carousel-container"
  itemClass="carousel-item-padding-40-px items"
>
          <div className="item">
      <i className="fa-solid fa-dollar-sign"></i>


            <p>Conta Digital</p>
          </div>

          
   
     

 <div className="item">
          <i className="fa-brands fa-pix"></i>
          <p>PIX</p>
          </div>


  <div className="item">
        <i className="fa-solid fa-money-bill-transfer"></i>

            <p>Transferências</p>
          </div>
    
 
    <div className="item">
        <i className="fa-regular fa-credit-card"></i>
          <p>Cartão de Crédito
e adiciona</p>

          </div>
     
 
    <div className="item">
          <i className="fa-solid fa-hand-holding-dollar"></i>
            <p>Pague
contas</p>
          </div>

        
       




            </Carousel>
      


    </Col>
 
    </Row>

</div>

<div className="conheca">
<div className="container">
<div className="row py-4 ">
<div className="col-sm flex ">
<img src="celularmao.png"  style={{zIndex: '999'}} className="m-auto" />
</div>
<div className="col-sm flex order-mobile">
<div className="m-auto">
        <h2>
        Baixe nosso aplicativo e abra sua conta
em poucos minutos.
</h2>

        </div>
</div>
</div>
</div>
</div>
</div>


</Pagina>
</>);

});