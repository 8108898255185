import { useContext } from "react";
import { QrCodeAppContext } from "../../../template/qrcodeappContext";
import "./venha.sass";
export function VenhaParaIbipay() {
  const {isOpen,toggle} = useContext(QrCodeAppContext)

  return (
    <>
      <div className="venhapara">
 
        <div className=" container">
          <div className="row">
          <div className="col-sm-7 " style={{position: 'relative'}}>
            <img src="cartoes.png" className="cartoes" alt="Imagem dos cartões da ux bank" />
          </div>
          <div className="col-sm-5">
            <div className="m-auto texto">
            <h2 className="m-0 p-0">
          
            Viva essa Experiência<br></br>
	  
            </h2>
            <h4>
            <strong style={{color: "#065ED1",fontSize: '5rem'}}>ux bank</strong>
            </h4>
            <p>
            Temos o ecosistema perfeito para você, abra sua conta e viva a experiência e oportunidade de revolucionar o seu acesso ao mercado 	 financeiro.

            </p>
            <p>
            Venha para nosso time, clique no botão abaixo e começe a sua experiência.</p>
         
            <div>
              <a
                href="https://webbanking.uxbank.secure.srv.br/" target={"_blank"} 
                className="ui btn btn-primary"
              >
             Abra sua conta! 
              </a>
            </div>
            </div>
            </div>
            </div>
        
        </div>
      </div>
    </>
  );
}
