import React,{ memo } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Pagina } from '../../template/pagina/pagina';
import GoogleMapReact from 'google-map-react';
import { Header } from "../../template/header/header";
import './contato.sass'

export default memo(function ContatoPagina() {

    const AnyReactComponent = ({ text }) => (
        <div style={{
          color: 'white',
          background: 'linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) ',
          padding: '5px',
          display: 'inline-flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '.3rem',
          transform: 'translate(-50%, -50%)'
        }}>
          {text}
        </div>
      );

return(<>
<Pagina >
<>
<Header titulo={"Quer falar com a gente?"} subtitulo={"Canais de Atendimento"} icon={"fa-solid fa-comments"} img={'./fale-conosco.jpg'}></Header>
<Container className="contato py-4">
    
<Row>
<Col sm>
<h3>
Central de Relacionamento
</h3>
<p>Se quiser falar com a gente por telefone, nosso setor de atendimento está disponível a qualquer hora, é só ligar em um dos números abaixo:

  <small>Central de Relacionamento Segunda a sexta, das 8h às 20h e sábados das 8h às 14h (exceto feriados)</small>
</p>
<Row sm="3" className="g-4 ">
  

<Col sm>
        <Card bg="light" text="dark" className="p-4" >
        <Card.Body>
        <Card.Text>
          Capitais e regiões metropolitanas
          </Card.Text>
          <Card.Title> <i class="fa-solid fa-phone"></i> +55 (11) 91608-0863</Card.Title>
   
        </Card.Body>

      </Card>
      </Col>
      <Col sm>
      <Card bg="light" text="dark" className="p-4" >
        <Card.Body>
        <Card.Text>
        Outras localidades
          </Card.Text>
          <Card.Title> <i class="fa-solid fa-phone"></i> +55 (11) 91608-0863</Card.Title>
   
        </Card.Body>

      </Card>
      </Col>
      <Col sm>
      <Card bg="light" text="dark" className="p-4" >
        <Card.Body>
        <Card.Text>
        Deficientes auditivos ou de fala WhatsApp
          </Card.Text>
          <Card.Title> <i class="fa-brands fa-whatsapp"></i> +55 (11) 91608-0863</Card.Title>
   
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="light" text="dark" className="p-4" >
        <Card.Body>
        <Card.Text>
        SAC (Reclamações, dúvidas e sugestões)
atendimento 24h
          </Card.Text>
          <Card.Title> <i class="fa-solid fa-phone"></i> (11) 91608-0863

</Card.Title>
   
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="light" text="dark" className="p-4" >
        <Card.Body>
          <Card.Text>
          Ouvidoria
segunda a sexta, das 10h às 16h
(exceto feriados)
      </Card.Text>
          <Card.Title> <i class="fa-solid fa-phone"></i> (11) 91608-0863
</Card.Title>
   
        </Card.Body>

      </Card>
      </Col>

 
</Row>
</Col>


    </Row>
    <Row>
    <Col sm>

    </Col>
    <Col sm={'12'} >


    <Form className="p-4">
    <h2 className="py-4">Fale Ouvidoria</h2>
    <p>
    Entramos em contato com você. Se você já tem um protocolo registrado, deixe suas informações que entraremos em contato.
    </p>
    <Row>
    <Col sm>
    <Form.Group sm={'6'}  className="mb-3 " controlId="exampleForm.ControlInput1">
        <Form.Label>Nome</Form.Label>
        <Form.Control type="email" placeholder="Nome Completo" />
      </Form.Group>
    </Col>
    <Col sm>
        
      <Form.Group sm={'6'}  className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Telefone</Form.Label>
        <Form.Control type="email" placeholder="Telefone" />
      </Form.Group>
        </Col>

      

    </Row>
    <Row>
    <Col sm>
        
        <Form.Group sm={'6'}  className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>CPF/CNPJ</Form.Label>
          <Form.Control type="email" placeholder="CPF/CNPJ" />
        </Form.Group>
          </Col>

          <Col sm>
        
        <Form.Group sm={'6'}  className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>E-mail</Form.Label>
          <Form.Control type="email" placeholder="email@gmail.com" />
        </Form.Group>
          </Col>
    </Row>

    <Row>
    <Col sm>
        
    <Form.Group sm={'6'}  className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Motivo do Contato</Form.Label>
          <Form.Control type="email" placeholder="name@example.com" />
        </Form.Group>
          </Col>

          <Col sm>
        
    
          </Col>
  
      </Row>

      <Row>
    <Col sm>
        
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Mensagem</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
          </Col>
  
      </Row>
      <Row>
    <Col sm>
    <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Autorizo contato da UX Bank para prestação desse serviço. Dúvidas acesse nossa Política de Privacidade." />
      </Form.Group>
      <Button variant="primary" type="submit">
        Enviar 
      </Button>
    </Col>
    </Row>
    </Form>
        </Col>
    </Row>
    
</Container>

</>
</Pagina>
</>);

});