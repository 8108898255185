import { Link } from "react-router-dom";
import "./sobre.sass";
export function Sobre() {
  return (
    <>
      <div className="sobre">
        <div className="container">
        <div className="row">
        <div className="col-sm-6 d-flex ">

<div className="m-auto texto">
  <div className="texto-left">
  <h3>Sobre o </h3>
  <h4>ux bank</h4>
  </div>

<p>
É um prazer poder apresentar a UX Bank, somos uma instituição financeira digital inovadora e comprometida em aprimorar a experiência dos nossos clientes no mercado financeiro.<br></br> 
Com esse propósito em mente, desenvolvemos o conceito "UX" (User eXperience).<br></br> 
Nossa missão é democratizar, simplificar e transformar a experiência dos nossos clientes com relacinamento e atendimento personalizado.</p>
<div>
<Link className="link" to={'/sobre'}>
<i class="fa-solid fa-circle-arrow-right"></i>        Ver institucional
</Link>
</div>
</div>

</div>
        <div className="col-sm-6 order-mobile" style={{position: 'relative'}}>
        <div className="efeito "  alt="Mulher segurando cartão ux bank preto" style={{backgroundImage: `url('./mulheruxbank.png')`}}>
   
        </div>

        </div>
    
        </div>
        </div>
   

      
      </div>
    </>
  );
}
