import React, { memo } from "react";
import { Card, Container, Table } from "react-bootstrap";
import { Header } from "../../template/header/header";
import { Pagina } from '../../template/pagina/pagina';
import './politicas.sass'
export default memo(function PoliciadeCSISPagina() {
        return (<>
                <Pagina>
                        <>
                                <Header titulo={"   POLÍTICA CORPORATIVA DE SEGURANÇA DA INFORMAÇÃO E CYBER SECURITY (GLOBAL) "} subtitulo={" "}  img={'./../politica-de-privacidade.jpg'}></Header>


                                <Container className="politicas">
                                       <h4>OBJETIVO</h4>
                                       <p>Estabelecer os princípios, diretrizes e atribuições relacionadas à segurança da informação, protegendo as informações da instituição, dos clientes e do público em geral, observando as melhores práticas de mercado e regulamentações aplicáv</p>
                        
                                        <br></br>
                             

                        
                                        <h4>PÚBLICO-ALVO </h4>
                             <p>

                             Colaboradores do UX Bank e suas empresas controladas no Brasil e no exterior e entidades mantidas ou geridas pelo Conglomerado SkyW3b Administração e Investimentos LTDA. 
                             </p>


                             <h4>INTRODUÇÃO</h4>
                             <p>
                             A informação é um dos principais bens da instituição. Assim, o UX Bank define a estratégia de segurança da Informação e Cyber Security para proteger a integridade, disponibilidade e 
confidencialidade da informação.       <br></br>
Esta estratégia é baseada na detecção, prevenção, monitoramento e resposta à incidentes e fortalece a gestão do risco de segurança cibernética e a construção de um alicerce robusto para o futuro cada vez mais digital do UX Bank.        <br></br>
Para alcançarmos esse objetivo, utilizamos a estratégia de proteção de perímetro expandido. Esse conceito considera que a informação deve ser protegida independentemente de onde esteja, seja internamente, em uma coligada, em um serviço de cloud, em um prestador de serviço ou em uma unidade internacional, em todo o seu ciclo de vida, desde a coleta até o descarte. 
                             </p>

                             <h4>

                             PRINCÍPIOS DE SEGURANÇA DA INFORMAÇÃO 

                             </h4>
                             <p>

                             Nosso compromisso com o tratamento adequado das informações do UX Bank, clientes e público em geral está fundamentado nos seguintes princípios: 
                <ul>
                <li>
                <strong>Confidencialidade: </strong> garantir que o acesso à informação seja obtido somente por pessoas autorizadas; 
                                </li>

                                <li>
                               <strong> Disponibilidade: </strong> garantir que as pessoas autorizadas tenham acesso à informação sempre que necessário; 
 
                                </li>

                                <li>
                                <strong>Integridade:</strong> garantir a exatidão e a completude da informação e dos métodos de seu processamento, bem como da transparência no trato com os públicos envolvidos. 

                                </li>

                </ul>
                             </p>

                             <h4>DIRETRIZES</h4>
                             <p>

                             em local acessível aos colaboradores e protegidos contra alterações. <br></br>
A Política Corporativa de Segurança da Informação e Cyber Security é revisada anualmente pelo UX Bank com aplicação no Brasil e no exterior.  <br></br>
A inclusão de diretrizes ou exceções por requisito regulatório e a publicação nas unidades do exterior, serão identificadas pelo responsável por segurança da informação da unidade, que deverá formalizar e submeter de forma prévia a proposta de diretrizes ou exceções para aprovação pela Diretoria de Segurança Corporativa. <br></br>
A adesão à essa Política e eventuais desvios, no Brasil e nas unidades no exterior, são reportados periodicamente pela Diretoria de Segurança Corporativa aos Comitê Executivo, Comitê de Auditoria e 
demais comitês de risco. <br></br>
A informação deve ser utilizada de forma transparente, para as finalidades informadas ao cliente e de acordo com a legislação vigente, conforme descrito em políticas internas.  <br></br>
As diretrizes e eventuais exceções são complementadas em procedimentos com regras específicas que devem ser observadas. 


                             </p>

                             <h4>PROCESSOS DE SEGURANÇA DA INFORMAÇÃO  </h4>
                             <p>

                             Para assegurar que as informações tratadas estejam adequadamente protegidas, o UX Bank adota os seguintes processos:

                             </p>

                             <h4></h4>
                             <p>
                             <ol type="a">
                                         
                             <li>
                                                        <strong>Gestão de Ativos  </strong>
                                                                <p>
                                                                Entende-se por ativo, tudo aquilo que a instituição considerar como relevante para o negócio, desde ativos tecnológicos (p.ex. software e hardware) como não tecnológicos (p.ex. pessoas, processos e dependências físicas) desde que estejam relacionados à proteção da informação.  <br></br>
Os ativos, de acordo com sua criticidade, devem ser identificados, inventariados, mantidos atualizados, possuírem um proprietário, descartados de forma segura e serem protegidos contra acessos indevidos. A proteção pode ser, física (p.ex. salas com acesso controlado) e lógica (p.ex. configurações de blindagem ou hardening, patch management, autenticação e autorização).   <br></br>
Os ativos do UX Bank, dos clientes e do público em geral devem ser tratados de forma ética e sigilosa e de acordo com as leis vigentes e normas internas, promovendo o uso adequado e prevenindo exposição indevida das informações. 
                                                                </p>
                                                                
                                                                 </li>
                                                                 <li>
                                                        <strong>Classificação da Informação   </strong>
                                                                <p>
                                                                As informações devem ser classificadas de acordo com a confidencialidade, conforme descrito em documento internos. <br></br>
Para isso, devem ser consideradas as necessidades relacionadas ao negócio, o compartilhamento ou restrição de acesso e os impactos no caso de utilização indevida das informações. De acordo com a classificação da confidencialidade devem ser estabelecidas as proteções necessárias durante todo o seu ciclo de vida. <br></br>
O ciclo de vida da informação compreende: Geração, Manuseio, Armazenamento, Transporte e Descarte    </p>
                                                                
                                                                 </li>
                                                                 <li>
                                                        <strong>Gestão de Acessos   </strong>
                                                                <p>
                                                                As concessões, revisões e exclusões de acesso devem utilizar as ferramentas e os processos corporativos do UX Bank. <br></br>
Os acessos devem ser rastreáveis, a fim de permitir a identificação individual do colaborador ou prestador de serviço que tenha acessado ou alterado as informações, permitindo sua responsabilização. <br></br>
A concessão de acessos deve obedecer ao critério de menor privilégio, no qual os usuários devem ter acesso somente aos recursos de informação imprescindíveis para o pleno desempenho de suas atividades e devidamente autorizados. <br></br>
A segregação de funções deve permear todos os processos críticos, evitando que um único responsável possa executar e controlar o processo durante todo seu ciclo de vida. <br></br>
A identificação de qualquer colaborador deve ser única, pessoal e intransferível, qualificando-o como responsável pelas ações realizadas. <br></br>
A senha é uma informação confidencial, pessoal e intransferível, deve ser utilizada como assinatura eletrônica, sendo proibido seu compartilhamento.                 </p>
                                                                
                                                                 </li>
                                                                 <li>
                                                        <strong>Gestão de Riscos </strong>
                                                                <p>
                                                                Os riscos devem ser identificados por meio de um processo estabelecido para análise de ameaças, vulnerabilidades, probabilidades e impactos sobre os ativos do UX Bank, para que sejam recomendadas as proteções adequadas. As recomendações são discutidas nos fóruns apropriados. <br></br>
Produtos, processos e tecnologias devem ter a adequada gestão dos riscos de Segurança da Informação, para redução dos riscos à níveis aceitáveis, independentemente de estarem dentro da infraestrutura do UX Bank, parceiros ou prestadores de serviços. <br></br>
As tecnologias em uso pela instituição devem estar em versões suportadas pelos seus fabricantes e devidamente atualizadas. Eventuais exceções devem ser aprovadas na alçada competente ou possuir controles compensatórios. <br></br>         </p>
                                                                
                                                                 </li>

                                                                 <li>
                                                        <strong>Gestão de Riscos em Prestadores de Serviços e Parceiros 
 </strong>
                                                                <p>
                                                                Os prestadores de serviços e parceiros contratados pelo banco devem ser classificados considerando alguns critérios, conforme em documentos internos. <br></br>
Dependendo da classificação, o prestador de serviço ou parceiro passará por avaliação de risco, que pode incluir a validação in loco dos controles de SI, avaliação remota das evidências ou outras avaliações, além do acompanhamento de eventuais correções e melhorias implementadas pelos prestadores de serviços e parceiros. <br></br>
Os prestadores de serviços e parceiros devem informar os incidentes relevantes (conforme definido no item 6.f deste documento), relacionados às informações do UX Bank armazenadas ou processadas por eles em cumprimento às determinações legais e regulamentares. <br></br>
As diretrizes para contratação de serviços relevantes conforme definições regulamentares, estão descritas em documento internos. 

 </p>
                                                                
                                                                 </li>

                                                                 <li>
                                                        <strong>Tratamento de Incidentes de Segurança da Informação e Cyber Security 
 </strong>
                                                                <p>
                                                                A área de Cyber Security monitora a segurança do ambiente tecnológico do UX Bank no Brasil, analisando os eventos e alertas para identificar possíveis incidentes.  
                                                                <br></br>Os incidentes que são identificados pelos alertas são classificados com relação ao impacto, de acordo com os critérios adotados pelo UX Bank. Para o seu grau de relevância serão considerados aspectos como impacto ao sistema financeiro e comprometimento de dados de clientes e do público em geral, conforme descrito no Plano de Tratamento de Incidentes de Segurança Da Informação e Cyber Security (Brasil). Incidentes classificados como relevantes devem ser comunicados ao Regulador, ao titular do dado, e ao Comitê de Auditoria (CAUD), quando envolverem dados pessoais que possam acarretar risco ou causar dano relevante aos titulares e havendo o envolvimento de colaboradores internos, os casos serão reportados para atuação conjunta com a Inspetoria.  <br></br>
Todos os incidentes passam por um processo de tratamento e comunicação, onde são registradas todas as informações pertinentes aos incidentes como causa, impacto, classificação, etc. <br></br>
Informações sobre incidentes que possam impactar outras instituições financeiras no Brasil, devem ser compartilhadas com as demais instituições, visando colaborar com a mitigação do risco conforme determinações legais e regulamentares. <br></br>
No exterior, a gestão de incidentes de segurança da informação e cibernéticos é realizada por cada Unidade Internacional que deve reportá-los tempestivamente à Diretoria de Segurança Corporativa no Brasil. <br></br>
A área de Riscos elaborará Relatório Anual contendo os incidentes relevantes ocorridos no período, ações realizadas de prevenção e resposta aos incidentes e resultados dos testes de continuidade. Este relatório deverá ser apresentado ao Comitê de Risco, ao Comitê de Auditoria e ao Conselho de Administração, conforme determinações legais e regulamentares. <br></br>
Visando aprimorar a capacidade de resposta a incidentes, o UX Bank realiza testes de continuidade de negócios simulando cenários de incidentes críticos de Cyber Security, que podem comprometer a disponibilidade e/ou a confidencialidade das informações. <br></br>
Todo colaborador deve ser proativo e diligente na identificação, comunicação para a área de 
Segurança da Informação e na mitigação dos riscos relacionados à segurança da informação. 

    </p>
                                                                
                                                                 </li>

                                                                 <li>
                                                        <strong>Conscientização em Segurança da Informação e Cyber Security  </strong>
                                                                <p>
                                                                O UX Bank promove a disseminação dos princípios e diretrizes de Segurança da Informação por meio de programas de conscientização e capacitação para fortalecer a cultura de Segurança da Informação, fazendo parte do Programa de Integridade e Ética, conforme descrito em documento interno.  <br></br>
Periodicamente, são disponibilizadas campanhas de conscientização ou treinamentos que podem ser presenciais ou on-line, relacionados a confidencialidade, integridade e disponibilidade da informação. Estas campanhas são veiculadas através de e-mails, portal corporativo, e-learning, elemídias ou redes sociais aos colaboradores e clientes. 
     
                                                           </p>
                                                                 </li>

                                                            
                                                                 <li>
                                                        <strong>Governança com as Áreas de Negócio e Tecnologia  </strong>
                                                                <p>
                                                                As iniciativas e projetos das áreas de negócio e tecnologia devem estar alinhadas com os princípios e diretrizes de segurança da informação. </p>
                                                                 </li>


                                                                 <li>
                                                        <strong>Segurança Física do Ambiente  </strong>
                                                                <p>
                                                                O processo de Segurança Física estabelece controles relacionados à concessão de acesso físico aos ambientes, de acordo com a criticidade das informações tratadas nestes ambientes, conforme descrito em documento internos. 
 
</p>
                                                                 </li>



                                                                 <li>
                                                        <strong>Segurança no Desenvolvimento de Sistemas de Aplicação  </strong>
                                                                <p>
                                                                O processo de Segurança Física estabelece controles relacionados à concessão de acesso físico aos ambientes, de acordo com a criticidade das informações tratadas nestes ambientes, conforme descrito em documento internos. 

</p>
                                                                 </li>



                                                                 <li>
                                                        <strong>Configuração Segura  </strong>
                                                                <p>
                                                        	O processo de Segurança Física estabelece controles relacionados à concessão de acesso físico aos ambientes, de acordo com a criticidade das informações tratadas nestes ambientes, conforme descrito em documento internos. 
</p>
                                                                 </li>
                                                                 <li>
                                                        <strong>Gestão de Riscos </strong>
                                                                <p>
                                                                Os riscos devem ser identificados por meio de um processo estabelecido para análise de ameaças, vulnerabilidades, probabilidades e impactos sobre os ativos do UX Bank, para que sejam recomendadas as proteções adequadas. As recomendações são discutidas nos fóruns apropriados. <br></br>
Produtos, processos e tecnologias devem ter a adequada gestão dos riscos de Segurança da Informação, para redução dos riscos à níveis aceitáveis, independentemente de estarem dentro da infraestrutura do UX Bank, parceiros ou prestadores de serviços. <br></br>
As tecnologias em uso pela instituição devem estar em versões suportadas pelos seus fabricantes e devidamente atualizadas. Eventuais exceções devem ser aprovadas na alçada competente ou possuir controles compensatórios. <br></br>         </p>
                                                                
                                                                 </li>

                                                                 <li>
                                                        <strong>Gravação de Logs </strong>
                                                                <p>
                                                                É obrigatória a gravação de logs ou trilhas de auditoria do ambiente computacional, para todas as plataformas, de forma a permitir identificar: quem fez o acesso, quando o acesso foi feito, o que foi acessado e como foi acessado. 
                                                                </p>
                                                                 </li>

                                                                 <li>
                                                        <strong>Programa de Cyber Security  </strong>
                                                                <p>
                                                                O Programa de Cyber Security do UX Bank é norteado pelos seguintes princípios:  
                                                                <ul>
                                                                <li>Regulamentações vigentes;  </li>
                                                                <li>Melhores práticas;  </li>
                                                                <li>Cenários mundiais; </li>
                                                                <li>Análises de risco da própria instituição. </li>
                                                                Conforme sua criticidade, as ações do programa dividem-se em:  
                                                                <li><strong>Críticas: </strong> Consiste em correções emergenciais e imediatas para mitigar riscos iminentes; </li>
                                                                <li><strong>Sustentação: </strong> Iniciativas de curto/médio prazo, para mitigação de risco no ambiente atual, mantendo o ambiente seguro, respeitando o apetite de risco da instituição e permitindo que ações de longo prazo/estruturantes possam ser realizadas; </li>
                                                                <li><strong>Estruturantes:</strong> Iniciativas de médio/longo prazo que tratam a causa raiz dos riscos e que preparam o banco para o futuro. </li>
                                                           
                                                                </ul>
                                                                </p>
                                                                 </li>

                                                                 <li>
                                                        <strong>Proteção de perímetro </strong>
                                                                <p>
                                                                Para proteção da infraestrutura do UX Bank contra um ataque externo, utilizamos, no mínimo, ferramentas e controles contra: ataques de DDoS, Spam, Phishing, APT/Malware, invasão de dispositivos de rede e servidores, ataques a aplicação e scan externos, além de testes de penetração.<br></br>  
Para mitigação do risco de vazamento de informações utilizamos ferramentas preventivas instaladas em dispositivos móveis, estações de trabalho, no serviço de correio eletrônico, no serviço de navegação WEB, no serviço de impressão, além do uso de criptografia para dados em repouso e em transporte. <br></br>
Visando elevar a proteção, não é permitida a conexão física ou lógica à rede corporativa da instituição, por equipamentos particulares não gerenciados ou não homologados. 
</p>
                                                                 </li>

                                                                 <li>
                                                        <strong>Governança com as Unidades Internacionais  </strong>
                                                                <p>
                                                                As unidades internacionais devem possuir um responsável por segurança da informação, independente das áreas de negócio e tecnologia, que se reporta matricialmente à Diretoria de Segurança Corporativa. </p> 
                                                                 </li>
                                       

                                        </ol>
                             </p>

                             <h4>Propriedade Intelectual </h4>
                             <p>
                             A propriedade intelectual é a proteção que recai sobre bens imateriais, tais como: marcas, sinais distintivos, slogans publicitários, nomes de domínio, nomes empresariais, indicações geográficas, desenhos industriais, patentes de invenção e de modelo de utilidade, obras intelectuais (tais como obras literárias, artísticas e científicas, base de dados, fotografias, desenhos, ilustrações, projetos de arquitetura, obras musicais, obras audiovisuais, textos e etc.), programas de computador e segredos empresariais (inclusive segredos de indústria e comércio). <br></br>  
Pertencem exclusivamente ao UX Bank todas e quaisquer invenções, criações, obras e aperfeiçoamentos que tenham sido ou venham a ser criados ou realizados pelo colaborador ao UX Bank, na qualidade de administrador, empregado e/ou estagiário, durante todo o prazo de vigência do mandato, contrato de trabalho ou contrato de estágio do colaborador. Quaisquer informações e conteúdos cuja propriedade intelectual pertença ao UX Bank, ou tenham sido por ele disponibilizado, inclusive informações e conteúdo que tenham sido obtidos, inferidos ou desenvolvidos pelo próprio colaborador em seu ambiente de trabalho ou utilizando recursos da instituição não devem ser utilizados para fins particulares, nem repassados a terceiros, sem autorização prévia e expressa do UX Bank   <br></br>  
É dever de todos os colaboradores zelar pela proteção da propriedade intelectual do UX Bank. 
                             </p>

                             <h4>Declaração de Responsabilidade </h4>
                             <p>
                             Periodicamente os colaboradores do UX Bank devem aderir formalmente a um termo, comprometendo-se a agir de acordo com as políticas de Segurança da Informação. 
Os contratos firmados com o UX Bank devem possuir cláusula que assegure a confidencialidade das informações e a obrigatoriedade de seguir as regulamentações vigentes, referentes ao tema de segurança da informação. 
                             </p>

                             <h4>PAPÉIS E RESPONSABILIDADES </h4>
                             <p>
                             As políticas, estratégias e processos corporativos de Segurança da Informação são supervisionadas no Brasil e no exterior pela Diretoria de Segurança Corporativa e discutidos nos fóruns específicos de riscos das áreas e nas Comissões Executivas que tratam Risco Operacional ou Tecnologia. 

                             </p>

                             <h4>Auditoria Interna </h4>
                             <p>
                             Os papéis e responsabilidades da Auditoria Interna estão descritos na Política de Auditoria Interna (Global). 
                             </p>

                             <h4></h4>
                             <p>
                             Os papéis e responsabilidades de Risco Operacional estão descritos na Política de Gerenciamento Integrado de Risco Operacional e Controles Internos (Global). 


                             </p>

                             <h4></h4>
                             <p>

                                <ul>
                                        <li>
                                        Aprimorar a qualidade e efetividade de seus processos, buscando a integridade, disponibilidade e confidencialidade das informações; 

                                        </li>
                                        <li>
                                        Proteger a informação de ameaças buscando garantir a continuidade do negócio e minimizar os riscos ao negócio; 

                                                </li>
                                                <li>
                                                Estabelecer, implementar, operar, monitorar e garantir a melhoria contínua do sistema de gestão de segurança da informação (SGSI). 
                                                </li>
                                                <li>
                                                Definir e formalizar os objetivos, controles e a estratégia de governança de segurança da informação, em conjunto com o Comitê Executivo de Segurança da Informação. 

                                                </li>
                                                <li>
                                                Coordenar as ações para atingimento dos objetivos e da estratégia de governança de segurança da informação aprovados pelos comitês, envolvendo as áreas responsáveis. 

                                                </li>
                                                <li>
                                                Estabelecer e disseminar uma cultura de segurança da informação. 

                                                </li>
                                                <li>
                                                Propor o investimento para a segurança da informação para atender aos objetivos desta política. 

                                                </li>
                                                <li>
                                                Definir as políticas e padrões de segurança da informação a serem aplicados nos processos, produtos e tecnologias. 

                                                </li>
                                                <li>
                                                Definir padrões mínimos de segurança para as Unidades Internacionais e Empresas controladas no Brasil e no exterior e Entidades mantidas ou geridas pelo Conglomerado UX Bank, garantindo alinhamento com os objetivos de segurança da informação definidos pela Holding. 

                                                </li>
                                           
                                </ul>
                             </p>

                             <h4>Unidades Internacionais </h4>
                             <p>Atuar proativamente na identificação, prevenção e correção dos riscos e reportar periodicamente à Diretoria de Segurança Corporativa. 
</p>

                             <h4>Empresas e Entidades do Conglomerado 
</h4>
                             <p>
                             Empresas do conglomerado controladas no Brasil e no exterior e entidades mantidas ou geridas pelo Conglomerado UX Bank devem avaliar as diretrizes e requisitos estabelecidos nesta política e em seus anexos, reportando periodicamente à Diretoria de Segurança Corporativa os riscos identificados, adequando seus procedimentos de segurança internos conforme seu segmento de negócio e apetite de riscos. Estas empresas devem ser classificadas e ter modelo de governança baseado na avaliação de riscos, que considera os seguintes aspectos: Impacto na imagem da Holding, Modelo de Arquitetura e Conectividade com a Holding, e Volume de dados sensíveis armazenados. Este modelo de governança pode variar entre avaliação e acompanhamento direto de aderência aos controles definidos ou seguindo declaração de aderência a ser realizado pela própria empresa. 

                             </p>

                             <h4>Comitê Executivo de Segurança da Informação 
</h4>
                             <p>Aprovar a estratégia, objetivos, orçamento e ações necessárias para a mitigação dos riscos dos processos de segurança da informação. 
</p>
                             <h4>Comitê de Auditoria – CAUD 
</h4>
                             <p>Supervisionar a estratégia de gestão dos riscos, seus respectivos processos e controles internos, bem como acompanhar os projetos de segurança da informação do Conglomerado UX Bank. 
</p>
                             <h4>Área de Tecnologia 
</h4>
                             <p>Manter o parque tecnológico disponível e atualizado com os padrões de segurança implementados, dentro dos prazos compatíveis com os níveis de riscos. 
</p>
                             <h4>Área de Negócio 
</h4>
                             <p>Área de Negócio 
</p>
                             <h4>SANÇÕES DISCIPLINARES                                    </h4>
                             <p>As violações a esta política estão sujeitas às sanções disciplinares previstas em documento internos, bem como nas normas internas das empresas do UX Bank e na legislação vigente onde as empresas estiverem localizadas. 
</p>
                             <h4>DOCUMENTOS RELACIONADOS 
</h4>
                             <p>Esta Política Corporativa de Segurança da Informação é complementada por procedimentos específicos de Segurança da Informação em conformidade com os aspectos legais e regulamentares e aprovadas pelas Superintendência de Governança e Projetos de Cyber Security e Superintendência Operacional de Cyber Security, subordinadas à Diretoria de Segurança Corporativa, na estrutura da Área de Riscos e Finanças do UX Bank. 
</p>

<h4>Frameworks e Regulamentações </h4>
<p>
       <ul>
       <li>Resolução 4.893 do Banco Central; </li>
       <li>Resolução nº 85 do Banco Central; </li>
       <li>Resolução 4.752 do Banco Central; </li>
       <li>Lei Geral de Proteção de Dados Pessoais (LGPD) - Lei nº 13.709/2018; </li>
       <li>Resolução nº 35 da CVM; </li>
       <li>Resolução Conjunta Nº 1, de 4 de maio de 2020 (Open Finance); </li>
       <li>Circular 638 da SUSEP; </li>
       <li>Resolução CNSP n° 415 da SUSEP, de 30 de Julho de 2021 (Open Insurance); - E demais regulamentações e leis relacionadas ao tema. </li>
       <li>ABNT NBR ISO/IEC 27001:2013 - Tecnologia da informação - Técnicas de segurança - Sistemas de gestão   da segurança da informação – Requisitos; </li>
       <li>ABNT NBR ISO/IEC 27701:2019 – Técnicas de segurança – Extensão da ABNT NBR ISO/IEC 27001 e ABNT NBR ISO/IEC 27002 para gestão da privacidade da informação – Requisitos e diretrizes; </li>
        </ul> 
</p>

<h4>
GLOSSÁRIO 
</h4>
<p>
<strong>APT (Advanced Persintent Threat):</strong> ataques avançados persistentes. <br></br>
<string>Cyber Security:</string> é o termo que designa o conjunto de meios e tecnologias empregadas na defesa dos sistemas de informação, infraestrutura, redes de computadores e/ou dispositivos pessoais, com o objetivo de prevenir danos, roubo, intrusão, alterações ou destruição de informações. <br></br>
<strong>Dano Relevante:</strong> Ação que possa causar impacto à privacidade do indivíduo, podendo ocasionar risco elevado à sua integridade física ou moral. <br></br>
<strong>Parque tecnológico:</strong> conjunto de ativos de infraestrutura e sistemas de tecnologia.  <br></br>
<strong>Segregação de funções:</strong> consiste na separação das atividades entre áreas e pessoas potencialmente conflitantes ou que possuem informações privilegiadas, na qual, o colaborador não pode exercer mais que uma função nos processos de autorização, aprovação, execução, controle e contabilização.  
        
</p>


                             
                                </Container>

                        </>
                </Pagina>
        </>);

});